import { FC, useState, useEffect } from 'react'
import { Button } from '../../components/button'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import {
    MemberControllerService,
    MemberQuery,
    MemberUpdateForm,
  } from '../../services'
interface Props {
  closeModal: () => void
  setIsLoading: (isLoading: boolean) => void ,
}

export const SingleWordForm: FC<Props> = ({ closeModal, setIsLoading }) => {
    const [member, setMember] = useState({} as MemberQuery)
    const [singleWord, setSingleWord] = useState(member.singleWord)
    
    useEffect(() => {
        const fetchData = async () => {
            const memberRes = await MemberControllerService.getMember(0)
            setMember(memberRes)
        }
    
        fetchData().catch(apiErrorHandler)
    }, [])

    const update = () => {
      setIsLoading(true)
      MemberControllerService.updateMember({
        memberName: member.memberName,
        memberNameFurigana: member.memberNameFurigana,
        positionName: member.position?.positionName,
        singleWord: singleWord,
        profileImageUrl: member.profileImageUrl,
      } as MemberUpdateForm)
        .then(() => {
          closeModal()
          toast.success('プロフィールを更新しました')
        })
        .catch(err => {
          apiErrorHandler(err)
          setIsLoading(false)
        })
    }
  
    const changeSingleWord = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      // 100文字制限
      if (event.target.value.length > 100) {
        return
      }
      setSingleWord(event.target.value)
    }
  
    return (
        <div className="mt-5 mb-24 flex flex-col items-start gap-5 max-h-full md:max-h-[500px]">
        <div className='grid gap-[22px] w-full'>
        <p className="w-full">
          <label className="font-bold text-sm leading-normal block text-left">
                    ひとこと（100文字）</label>
                      <textarea
                      placeholder="興味のあるトピックを教えてください"
                      maxLength={100}
                      value={singleWord}
                      onChange={changeSingleWord}
                      className="block w-full p-3 px-4 mt-1 border-none rounded-xl bg-neutral-paleGray text-inherit placeholder-gray-400 disabled:text-gray-400 h-20 resize-none"
                    ></textarea>
        </p>
              <div className="fixed inset-x-0 bottom-0 flex justify-center mb-5 gap-2">
                <Button
                  className="w-32 px-6 py-3 rounded-full font-bold text-sm flex justify-center items-center btn-outline-base"
                  onClick={closeModal}
                >
                  キャンセル
                </Button>
                <Button
                  className="px-6 py-3 w-32 flex justify-center items-center btn-primary-base"
                  onClick={update}
                >
                  投稿する
                </Button>
              </div>
            </div>
      </div>
    )
  }
  