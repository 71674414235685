import { FC, useState, useEffect } from 'react'
import { Button } from '../../components/button'
import Datepicker from "react-tailwindcss-datepicker";
import { SuggestResults } from '../../components/suggestResults';
import { format } from 'date-fns';
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  CubeControllerService,
  AnnouncementControllerService
} from '../../services'
import { toast } from 'react-toastify'

interface Props {
  closeModal: () => void
  setIsLoading: (isLoading: boolean) => void
}

export const AnnounceForm: FC<Props> = ({ closeModal, setIsLoading }) => {
  // 告知タイトル
  const [title, setTitle] = useState('')
  const handleTitleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setTitle(event.target.value)
  }

  // 告知内容
  const [description, setDescription] = useState('')
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value)
  }

  // 告知終了日
  const [date, setValue] = useState<any>({ 
    startDate: null, 
    endDate: null
  });

  // 告知終了日:最大選択可能日
  const MAXIMUM_SELECTABLE_DAYS = 30;
  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + MAXIMUM_SELECTABLE_DAYS);
  
  
  
  // キューブ設定
  const [inputCube, setInputCube] = useState<string>("")
  
  const setInput = (value: string) => {
    setInputCube(value)
  }
  
  // キューブ設定:サジェスト候補を取得
  const getSuggests = async (value: string): Promise<string[]> => {
    return await CubeControllerService.suggestCubes(value)
    .then(res => {
      // 選択済みのcubeはsuggestから除外
       const uniqueCubes = res.filter(
        (item) => !selectedCubes.some((selected) => selected.cubeId === item.cubeId)
      );
      
      return uniqueCubes.map(cube => cube.cubeName)
    })
    .catch(err => {
      apiErrorHandler(err)
      return []
    })
  }

  // キューブ設定:キューブ選択
  type cubeType = {
    cubeName: string,
    cubeId: number
  }
  const [selectedCubes, setSelectedCubes] = useState<cubeType[]>([]);
  const selectCube = async(value: string): Promise<void> => {
    await CubeControllerService.suggestCubes(value)
      .then(res => {
        const selectedCube = res.find(cube => cube.cubeName === value);
        if(selectedCube) setSelectedCubes([...selectedCubes, selectedCube]);
      })
      .catch(err => {
        apiErrorHandler(err)
        return []
      })
  }
  const deleteCube =(cubeId: number): void=> {
  const updatedCubes = selectedCubes.filter(cube => cube.cubeId !== cubeId);
  setSelectedCubes(updatedCubes);
  }

  // 投稿ボタン
  const [isEmptyForm, setIsEmptyForm] = useState(false)
  useEffect(() => {
    // タイトル,内容,終了日が入力済みかを確認
    const isEmpty = !title || !description || !date.endDate;
    setIsEmptyForm(isEmpty)
  }, [title, description, date])


  const createAnnounce = async ()=> {
    const announceForm = {
      "title": title,
      "description": description,
      "endDate": format(date.endDate, 'yyyy-MM-dd'),
      "cubeIds": selectedCubes.map(cube => cube.cubeId)
    }
    setIsLoading(true)
    await AnnouncementControllerService.createAnnouncement(announceForm)
    .then(() => {
      toast.success('登録しました')
      closeModal()
      })
    .catch(err => {
      apiErrorHandler(err)
      return []
    })
    .finally(() => {
      setIsLoading(false)
    })
  }
  return (
    <>
      <div className="mt-5 mb-24 flex flex-col items-start gap-5 max-h-full md:max-h-[500px]">
        <div className='grid gap-[22px] w-full'>
        <div className="w-full">
          <label className="font-bold text-sm leading-normal block text-left">
            告知タイトル（最大40文字）
            <textarea
              className="resize-none h-12 py-3 px-4 block w-full mt-1 rounded-xl bg-neutral-paleGray font-normal text-base"
              name="description"
              placeholder="告知タイトルを記入しましょう"
              value={title}
              onChange={handleTitleChange}
              maxLength={40}
            ></textarea>
          </label>
        </div>
        <div className="w-full">
          <label className="font-bold text-sm leading-normal block text-left">
            告知内容（最大1000文字）
            <textarea
              className="resize-none h-[120px] py-3 px-4 block w-full mt-1 rounded-xl bg-neutral-paleGray font-normal text-base"
              name="description"
              placeholder="告知の内容を記入しましょう"
              value={description}
              onChange={handleDescriptionChange}
              maxLength={1000}
            ></textarea>
          </label>
        </div>
        <div className="w-full">
          <label className="font-bold text-sm leading-normal block text-left">
            告知終了日（最大30日後）
          </label>
          <div className='font-bold text-sm'>
            <Datepicker 
            asSingle={true}
            value={date} 
            onChange={newValue => setValue(newValue)}
            displayFormat={'YYYY/MM/DD'}
            useRange={false}
            minDate={today}
            maxDate={maxDate}
            primaryColor={"emerald"}
            i18n={"ja"}
            readOnly={true}
            placeholder="2024/01/01"
            containerClassName="md:w-[160px] relative"
            inputClassName="w-[160px] font-normal text-base py-3 px-4 mt-1 rounded-xl bg-neutral-paleGray z-10"
            toggleClassName="text-green font-bold absolute md:right-4 left-32 top-[52%] -translate-y-1/2 focus:outline-none"
            /> 
            </div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <label className="font-bold text-sm leading-normal block text-left flex flex-col gap-2">
          キューブ設定
            <p className='text-xs font-normal text-textSub'>すでに登録されているキューブを設定することで、そのキューブを持ったメンバーへ通知されます</p>
          </label>
            <div className="relative">
              <div className="absolute top-[52%] left-4 transform -translate-y-1/2 flex items-center">
                <svg
                  className="w-5 h-5 text-green"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.0918 16.9075L15.0002 13.8409C16.2002 12.3445 16.7814 10.4453 16.6242 8.5336C16.4669 6.62194 15.5832 4.84317 14.1548 3.56306C12.7263 2.28294 10.8617 1.59878 8.94427 1.65125C7.02686 1.70371 5.20243 2.48882 3.84612 3.84514C2.4898 5.20146 1.70469 7.02589 1.65222 8.94329C1.59976 10.8607 2.28392 12.7253 3.56403 14.1538C4.84415 15.5822 6.62292 16.466 8.53458 16.6232C10.4462 16.7804 12.3455 16.1993 13.8418 14.9992L16.9085 18.0659C16.986 18.144 17.0781 18.206 17.1797 18.2483C17.2812 18.2906 17.3902 18.3124 17.5002 18.3124C17.6102 18.3124 17.7191 18.2906 17.8206 18.2483C17.9222 18.206 18.0144 18.144 18.0918 18.0659C18.242 17.9105 18.326 17.7028 18.326 17.4867C18.326 17.2706 18.242 17.0629 18.0918 16.9075ZM9.16683 14.9992C8.01311 14.9992 6.88529 14.6571 5.926 14.0161C4.96672 13.3751 4.21904 12.4641 3.77753 11.3982C3.33602 10.3323 3.2205 9.15938 3.44558 8.02783C3.67066 6.89627 4.22624 5.85687 5.04204 5.04107C5.85785 4.22526 6.89725 3.66969 8.0288 3.44461C9.16036 3.21953 10.3332 3.33505 11.3992 3.77656C12.4651 4.21807 13.3761 4.96574 14.0171 5.92503C14.658 6.88432 15.0002 8.01213 15.0002 9.16585C15.0002 10.713 14.3856 12.1967 13.2916 13.2906C12.1977 14.3846 10.7139 14.9992 9.16683 14.9992Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <SuggestResults
                  className="max-w-full block w-full mt-1 p-3 pl-10 rounded-lg bg-gray-100 font-normal text-base"
                            placeholder="キューブを検索"
                            value={inputCube}
                            setValue={value =>setInput(value)}
                            onSelected={value => {
                              selectCube(value)
                            }}
                  getSuggests={getSuggests}/>

            </div>
            <ul className="flex flex-wrap gap-2 md:max-h-16 overflow-y-scroll">
              {Array.from(selectedCubes)
                .map((cube,index) =>
                  <li
                    className="relative p-1.5 pr-6 pl-2 border border-blue-200 bg-blue-50 inline-block rounded font-bold text-sm text-center"
                    key={index}>
                    {cube.cubeName}

                    <Button
                                  className="block absolute top-1/2 right-1 -translate-y-1/2 cursor-none"
                                  onClick={() =>
                                    deleteCube(cube.cubeId)
                                  }
                                >
                                  <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="cursor-pointer"
                                  >
                                    <path
                                      d="M13.1977 12.5002L16.5496 9.14828C16.7087 8.98948 16.7982 8.77399 16.7984 8.54921C16.7986 8.32444 16.7095 8.10879 16.5507 7.94971C16.3919 7.79062 16.1764 7.70114 15.9516 7.70094C15.7268 7.70075 15.5112 7.78985 15.3521 7.94865L12.0002 11.3006L8.64828 7.94865C8.4892 7.78957 8.27344 7.7002 8.04846 7.7002C7.82349 7.7002 7.60773 7.78957 7.44865 7.94865C7.28957 8.10773 7.2002 8.32349 7.2002 8.54846C7.2002 8.77344 7.28957 8.9892 7.44865 9.14828L10.8006 12.5002L7.44865 15.8521C7.28957 16.0112 7.2002 16.227 7.2002 16.4519C7.2002 16.6769 7.28957 16.8927 7.44865 17.0517C7.60773 17.2108 7.82349 17.3002 8.04846 17.3002C8.27344 17.3002 8.4892 17.2108 8.64828 17.0517L12.0002 13.6998L15.3521 17.0517C15.5112 17.2108 15.727 17.3002 15.9519 17.3002C16.1769 17.3002 16.3927 17.2108 16.5517 17.0517C16.7108 16.8927 16.8002 16.6769 16.8002 16.4519C16.8002 16.227 16.7108 16.0112 16.5517 15.8521L13.1977 12.5002Z"
                                      fill="#212643"
                                    />
                                  </svg>
                                </Button>
                  </li>

                )}
            </ul>
        </div>
      </div>
        <div className="flex justify-center right-0 bottom-0 left-0 mb-5 gap-2 md:mb-6 fixed max-w-full">
          <Button className="flex items-center justify-center bg-white text-green border border-green rounded-[40px] text-sm font-bold max-w-full py-3 px-6 text-center hover:cursor-pointer py-[10px] px-[22px] w-[130px]" onClick={closeModal}>
            キャンセル
          </Button>
          <Button
            disabled={isEmptyForm}
            className="block min-w-32 px-6 py-3 text-center text-white bg-green rounded-full font-bold text-sm m-0 hover:cursor-pointer hover:opacity-60
            disabled:opacity-100 disabled:border disabled:border-[#c9cfd5] disabled:bg-[#c9cfd5] disabled:cursor-not-allowed" 
            onClick={createAnnounce}
          >
            投稿する
          </Button>
        </div>
        </div>
    </>
  )
}
