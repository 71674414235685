import React, {
  ChangeEvent,
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  MemberCubeQuery,
  TimelineControllerService,
  TimelineReactionMemberQuery,
  TimelineCardQuery,
  MemberControllerService,
  TimelineCommentControllerService,
  MentionTargetMember,
  AnnouncementControllerService,
  AnnouncementQuery
} from '../../services'
import _ from 'lodash'
import { toast } from 'react-toastify'
import Loading from 'react-loading'
import SingleWordInput from '../../components/SingleWordInput'
import AccounceCardList from '../announce/AnnounceCardList'
import FirstTryMessage from '../onboarding/FirstTryMessage'
import SearchMemberMessage from '../onboarding/SearchMemberMessage'
import JoinCard from '../../components/timeLine/JoinCard'
import UpdateSingleWordCard from '../../components/timeLine/UpdateSingleWordCard'
import UpdateCubeCard from '../../components/timeLine/UpdateCubeCard'
import { QuestionCard } from '../../components/question/questionCard'
import apiErrorHandler from '../../api/apiErrorHandler'
import { TimelineComment } from './TimelineComment'
import AboutMessage from '../onboarding/AboutMessage'
import { useNavigate } from 'react-router-dom'
import env from '../../config/env'
import { SESSION_KEY } from '../../constants/session'
import {
  answerTimelineQuestion,
  deleteQuestionFromTimeline,
} from '../../lib/questionActions'

interface TimelineProps {
  memberId: number
  memberName: string
  singleWord: string | undefined
  profileImageUrl: string | undefined
  positionName: string | undefined
  memberCubes: MemberCubeQuery[]
  showAbout: boolean
  showFirstTry: boolean
  showFirstTryAfter: boolean
  getAboutMessage: () => void
  getFirstTryMessage: () => void
  getFirstTryAfterMessage: () => void
  closeModal?: () => void
}

interface TimelineSessionState {
  timelineCards: TimelineCardQuery[]
  page: number
  hasMore: boolean
  path: string
}

const Timeline: FC<TimelineProps> = ({
  memberId,
  memberName,
  singleWord,
  profileImageUrl,
  positionName,
  memberCubes,
  showAbout,
  showFirstTry,
  showFirstTryAfter,
  getAboutMessage,
  getFirstTryMessage,
  getFirstTryAfterMessage,
  closeModal,
}) => {
  const [timelineCards, setTimelineCards] = useState<TimelineCardQuery[]>([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [singleWordPlaceholder, setSingleWordPlaceholder] = useState(singleWord)
  const [singleWordState, setSingleWordState] = useState('')
  const [mentionTargetMembers, setMentionTargetMembers] = useState<
    MentionTargetMember[]
  >([])
  const [announcements, setAnnouncements] = useState<AnnouncementQuery[]>([]);

  const navigate = useNavigate()
  const isFirstRender = useRef(true)

  const goToTimeLineDetail = (timelineId: number) => {
    const timelineDetailPath = `/timeline-detail/${timelineId}`
    const state = {
      timelineCards: timelineCards,
      page: page,
      hasMore: hasMore,
      path: timelineDetailPath,
    } as TimelineSessionState
    sessionStorage.setItem(
      SESSION_KEY.TIMELINE.STORAGE_KEY,
      JSON.stringify(state),
    )
    navigate(timelineDetailPath, {
      state: { from: SESSION_KEY.TIMELINE.PAGE_NAME },
    })
  }

  const myTimelineReactionMemberQuery = {
    memberId: memberId,
    memberName: memberName,
    profileImageUrl: profileImageUrl,
    positionName: positionName,
  } as TimelineReactionMemberQuery

  // 初回レンダリング時に1ページ目のデータを取得
  useLayoutEffect(() => {
    const timelineSessionStr = sessionStorage.getItem(
      SESSION_KEY.TIMELINE.STORAGE_KEY,
    )
    if (timelineSessionStr && env.ENV !== 'mock') {
      // セッションを取得できた場合はデータを復元
      try {
        const sessionState = JSON.parse(
          timelineSessionStr,
        ) as TimelineSessionState
        const timelineDetailPath = sessionStorage.getItem(
          SESSION_KEY.TIMELINE.DETAIL_PATH_KEY,
        )
        // タイムライン詳細から戻った場合はセッションを復元
        if (timelineDetailPath === sessionState.path) {
          setTimelineCards(sessionState.timelineCards)
          setPage(sessionState.page)
          setHasMore(sessionState.hasMore)
          setIsLoading(false)
        }
      } catch (e) {
        console.log(e)
      } finally {
        // セッションを削除
        sessionStorage.removeItem(SESSION_KEY.TIMELINE.STORAGE_KEY)
        sessionStorage.removeItem(SESSION_KEY.TIMELINE.DETAIL_PATH_KEY)
      }
    } else {
      // セッションが無い場合はデフォルトのページを取得
      const defaultPage = 1
      TimelineControllerService.getTimeline(defaultPage)
        .then(res => {
          setIsLoading(false)
          if (res.timelineCards.length === 0) {
            setHasMore(false)
          } else {
            setTimelineCards(res.timelineCards)
            setPage(defaultPage + 1)
            setHasMore(true)
          }
        })
        .catch(apiErrorHandler)
    }
    getWorkspaceMembers()
    getAnnouncements();
  }, [closeModal, singleWordPlaceholder])

  // ページング用のデータを取得
  const fetchData = () => {
    setIsFetching(true)
    TimelineControllerService.getTimeline(page).then(res => {
      if (res.timelineCards.length === 0) {
        setHasMore(false)
      } else {
        setTimelineCards(oldData => [...oldData, ...res.timelineCards])
        setPage(oldPage => oldPage + 1)
      }
      setIsFetching(false)
    })
  }

  // スクロール位置の検出
  useEffect(() => {
    // 初回レンダリング時はスクロールイベントを設定しない
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const handleScroll = _.throttle(() => {
      // データ取得を開始する閾値を設定
      const threshold = 200
      // スクロールが閾値以下になったらデータフェッチを開始
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - threshold
      ) {
        return
      }
      // 追加のデータが無い場合、またはデータをフェッチ中の場合は処理を終了
      if (!hasMore || isFetching) {
        return
      }
      fetchData()
    }, 1000) // 1秒間に最大1回だけイベントハンドラが実行されるようにスロットリング

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [page, hasMore, isFetching])

  const updateSingleWord = () => {
    const newSingleWord = singleWordState
    MemberControllerService.updateMember({
      singleWord: newSingleWord,
    })
      .then(() => {
        toast.success('ひとことを更新しました')
        setSingleWordPlaceholder(newSingleWord)
        setSingleWordState('')
      })
      .catch(apiErrorHandler)
  }

  const changeSingleWord = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSingleWordState(event.target.value)
  }

  const getWorkspaceMembers = () => {
    TimelineCommentControllerService.getMentionTargetMembers().then(res => {
      setMentionTargetMembers(res)
    })
  }

   const getAnnouncements = () => {
     AnnouncementControllerService.getAnnouncements().then((announcements) => {
      // 終了日を迎えていない告知のみに絞る
      const filteredAnnouncements = announcements.filter(
        (announcement) => !announcement.isEnd
      );
      setAnnouncements(filteredAnnouncements);
    })
     .catch(apiErrorHandler);
    }

  // 質問の回答
  const answerQuestion = (questionId: number, choiceId: number) => {
    answerTimelineQuestion(
      questionId,
      choiceId,
      timelineCards,
      setTimelineCards,
      apiErrorHandler,
    )
  }

  // 質問の削除
  const deleteQuestion = (questionId: number) => {
    deleteQuestionFromTimeline(
      questionId,
      timelineCards,
      setTimelineCards,
      apiErrorHandler,
    )
  }

  return (
    <ul className="home-timeline">
      <div className="max-sm:mb-6">
        <AccounceCardList showButtons={true} showGradients={true}  announcements={announcements} cardClassName="md:first:ml-2 last:mr-2 first:ml-4"/>
      </div>

      <div className='mx-2 flex flex-col md:gap-4 gap-2'>
      {showAbout && <AboutMessage getAboutMessage={getAboutMessage} />}
      {showFirstTry && (
        <FirstTryMessage
          getFirstTryMessage={getFirstTryMessage}
          memberName={memberName}
        />
      )}
      {!showFirstTry && showFirstTryAfter && (
        <SearchMemberMessage
          getFirstTryAfterMessage={getFirstTryAfterMessage}
          memberName={memberName}
        />
      )}
      <SingleWordInput
        memberName={memberName}
        singleWordPlaceholder={singleWordPlaceholder}
        singleWord={singleWordState}
        updateSingleWord={updateSingleWord}
        changeSingleWord={changeSingleWord}
      />
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        timelineCards.map((timelineCard, index) => {
          switch (timelineCard.timelineCardType) {
            case 'JOIN':
              // eslint-disable-next-line no-case-declarations
              const joinMember = timelineCard.joinCard?.member
              return (
                joinMember && (
                  <JoinCard
                    key={joinMember.memberId}
                    memberId={memberId}
                    joinMember={joinMember}
                    timelineCard={timelineCard}
                    myTimelineReactionMemberQuery={
                      myTimelineReactionMemberQuery
                    }
                    timelineCommentComponent={
                      <TimelineComment
                        timelineId={timelineCard.timelineCardId}
                        timelineComments={
                          timelineCard.commentSummary.oldestComments
                        }
                        timelineCommentTotalCount={
                          timelineCard.commentSummary.commentCount
                        }
                        mentionTargetMembers={mentionTargetMembers}
                        onClick={() => {
                          goToTimeLineDetail(timelineCard.timelineCardId)
                        }}
                      />
                    }
                    onClickTimelineCard={() => {
                      goToTimeLineDetail(timelineCard.timelineCardId)
                    }}
                  />
                )
              )

            case 'UPDATE_SINGLE_WORD':
              // eslint-disable-next-line no-case-declarations
              const updateSingleWordMember =
                timelineCard.updateSingleWordCard?.member
              return (
                updateSingleWordMember && (
                  <UpdateSingleWordCard
                    key={index}
                    timelineCard={timelineCard}
                    myTimelineReactionMemberQuery={
                      myTimelineReactionMemberQuery
                    }
                    timelineCommentComponent={
                      <TimelineComment
                        timelineId={timelineCard.timelineCardId}
                        timelineComments={
                          timelineCard.commentSummary.oldestComments
                        }
                        timelineCommentTotalCount={
                          timelineCard.commentSummary.commentCount
                        }
                        mentionTargetMembers={mentionTargetMembers}
                        onClick={() => {
                          goToTimeLineDetail(timelineCard.timelineCardId)
                        }}
                      />
                    }
                    onClickTimelineCard={() => {
                      goToTimeLineDetail(timelineCard.timelineCardId)
                    }}
                  />
                )
              )
            case 'UPDATE_CUBE':
            case 'string': // mockの場合
              return (
                <>
                  <UpdateCubeCard
                    timelineCard={timelineCard}
                    memberId={memberId}
                    memberCubes={memberCubes}
                    index={timelineCard.timelineCardId}
                    timelineCommentComponent={
                      <TimelineComment
                        timelineId={timelineCard.timelineCardId}
                        timelineComments={
                          timelineCard.commentSummary.oldestComments
                        }
                        timelineCommentTotalCount={
                          timelineCard.commentSummary.commentCount
                        }
                        mentionTargetMembers={mentionTargetMembers}
                        onClick={() => {
                          goToTimeLineDetail(timelineCard.timelineCardId)
                        }}
                      />
                    }
                    onClickTimelineCard={() => {
                      goToTimeLineDetail(timelineCard.timelineCardId)
                    }}
                  />
                </>
              )

            case 'QUESTION': {
              // eslint-disable-next-line no-case-declarations
              const question = timelineCard.questionCard?.question
              return (
                question && (
                  <QuestionCard
                    key={question.questionId}
                    question={question}
                    activeFilter={'ALL'}
                    answerQuestion={answerQuestion}
                    deleteQuestion={deleteQuestion}
                    onMoreViewCommentClick={goToTimeLineDetail}
                  />
                )
              )
            }
          }
        })
      )}
      </div>
      {!isLoading && isFetching && (
        <Loading className="loading" type="spin" color="#007559" />
      )}
    </ul>
  )
}
export default Timeline
