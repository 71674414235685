import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CubeQuery } from '../../services';
interface AnnounceCardProps {
  memberName?: string;
  profileImageUrl?: string;
  title: string;
  description: string;
  cubes: CubeQuery[];
  isEnd: boolean;
  timelineId: number;
  cardClassName?: string;
}

const AccounceCard : React.FC<AnnounceCardProps> = ({ memberName="", profileImageUrl="", title, description, cubes, isEnd = false, timelineId, cardClassName="" }) => {
  
  const navigate = useNavigate()
  const onClickCard = () => {
    const announceDetailPath = `/timeline-detail/${timelineId}`
    navigate(announceDetailPath)
  }
  return (
    <div
      onClick={onClickCard}
      className={`
      ${cardClassName}
      relative z-index-1 flex flex-col gap-3 shadow-main cursor-pointer md:shadow-card rounded-lg p-4 min-w-80  max-w-80 h-[186px] my-2 ${isEnd ? "bg-disabled shadow-none": "bg-white"}`}
    >
      <div className={`flex gap-3 items-center ${isEnd && "text-[#b9bdc9]"}`}>
        <img
              src={
                profileImageUrl ? profileImageUrl : '/icons/avatar-sample.png'
              }
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                window.location.href = `/member-detail`
              }}
              alt="プロフィール画像"
              width={32}
              height={32}
              className='rounded-full'
            />
        <h2><span className='text-sm font-bold'>{memberName}</span>さんが告知しました。</h2>
      </div>
      <div className={`flex flex-col gap-1`}>
        <p className={`text-sm font-bold ${isEnd && "text-[#b9bdc9]"}`}>{title}</p>
        <p className={`w-full truncate text-xs text-annotation  ${isEnd && "text-[#b9bdc9]"}`}>{description}</p>
      </div>
      <div className='w-full'>
        <ul className='flex gap-3 flex-wrap max-h-10 overflow-hidden'>
        {cubes && cubes.map((cube, index) => (
          <li key={index} className='py-1.5 px-3 bg-seaBlue-sea-10 border border-seaBlue-sea-20 rounded-lg min-w-fit max-w-full font-bold truncate'>{cube.cubeName}</li>
        ))}
        </ul>
      </div>
    </div>
  )
}

export default AccounceCard
