const GradientOverlay = ({
    showLeft,
    showRight,
  }: {
    showLeft: boolean;
    showRight: boolean;
  }) => (
    <div className="pointer-events-none absolute inset-0 z-10">
      {showLeft && (
        <div className="absolute top-0 bottom-0 left-0 w-10 bg-gradient-to-r from-neutral-paleGray to-transparent"></div>
      )}
      {showRight && (
        <div className="absolute top-0 bottom-0 right-0 w-10 bg-gradient-to-l from-neutral-paleGray to-transparent"></div>
      )}
    </div>
  );
  
  export default GradientOverlay;
  