/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnouncementCreateForm } from '../models/AnnouncementCreateForm';
import type { AnnouncementQuery } from '../models/AnnouncementQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnnouncementControllerService {

    /**
     * @param memberId
     * @returns AnnouncementQuery OK
     * @throws ApiError
     */
    public static getAnnouncements(
        memberId?: number,
    ): CancelablePromise<Array<AnnouncementQuery>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/announcements',
            query: {
                'memberId': memberId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createAnnouncement(
        requestBody: AnnouncementCreateForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/announcements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param announcementId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAnnouncement(
        announcementId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/announcements/{announcementId}',
            path: {
                'announcementId': announcementId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
