import { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { AnnouncementQuery } from '../../services';

interface AnnounceDetailProps {
    announceDetail?: AnnouncementQuery
  }
export const AnnounceDetail: FC<AnnounceDetailProps> = ({ announceDetail }) => {
  const [endDate, setEndDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');

  useEffect(() => {
    if (announceDetail) {
        announceDetail.createdAt && setStartDate(format(new Date(announceDetail.createdAt), 'yyyy/MM/dd'));
        announceDetail.endDate && setEndDate(format(new Date(announceDetail.endDate), 'yyyy/MM/dd'));
    }
  }, [announceDetail]);
  return (
    announceDetail ? (
      <div className="flex flex-col gap-4 min-w-80 max-sm:max-w-80 my-2 md:p-4">
        <div className="flex gap-3 items-center">
          <img
            src={announceDetail.member?.profileImageUrl || '/icons/avatar-sample.png'}
            alt="プロフィール画像"
            width={32}
            height={32}
            className="rounded-full"
          />
          <div className="flex flex-col gap-2">
            <h2>
              <span className="text-sm font-bold">{announceDetail.member?.memberName}</span>さんが告知しました。
            </h2>
            <p className="w-full truncate text-xs text-annotation">
              告知期間：{startDate}〜{endDate}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1 py-3">
          <p className="text-sm font-bold">{announceDetail.title}</p>
          <p className="w-full text-sm">{announceDetail.description}</p>
        </div>
        <div className="w-full">
          <ul className="flex gap-3 flex-wrap">
            {announceDetail?.cubes?.map((cube, index) => (
              <li
                key={index}
                className="py-1.5 px-3 bg-seaBlue-sea-10 border border-seaBlue-sea-20 rounded-lg min-w-fit font-bold"
              >
                {cube.cubeName}
              </li>
            ))}
          </ul>
        </div>
      </div>
    ): null
  );
};
