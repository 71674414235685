import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';
import AnnounceCard from './AnnounceCard';
import GradientOverlay from './GradiantOverlay';
import ScrollButton from './ScrollButton';
import { AnnouncementQuery } from '../../services';

interface AccounceCardListProps {
  showButtons?: boolean; // ボタンの表示/非表示を切り替える
  showGradients?: boolean; // ボカシの表示/非表示を切り替える
  announcements?: AnnouncementQuery[];
  cardClassName?: string;
}

const AccounceCardList: React.FC<AccounceCardListProps> = ({
  showButtons = true,
  showGradients = true,
  announcements = [],
  cardClassName = ""
}) => {
  const OPTIONS: EmblaOptionsType = { dragFree: true };
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const [selectedSnap, setSelectedSnap] = useState(0);
  const [snapCount, setSnapCount] = useState(0);

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  const updateScrollSnapState = useCallback((emblaApi: EmblaCarouselType) => {
    setSnapCount(emblaApi.scrollSnapList().length);
    setSelectedSnap(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (emblaApi) {
      updateScrollSnapState(emblaApi);
      emblaApi.on('select', updateScrollSnapState);
      emblaApi.on('reInit', updateScrollSnapState);
    }
  }, [emblaApi, updateScrollSnapState]);

  return (
    <div className="relative">
      {/* ボカシ表示の切り替え */}
      <div className="hidden md:block">
      {showGradients && (
        <GradientOverlay showLeft={selectedSnap + 1 > 1} showRight={selectedSnap + 1 < snapCount} />
      )}
      </div>

      <div className="overflow-x-auto scrollbar-none white-space-nowrap" ref={emblaRef}>
        <div className="flex gap-4">
          {announcements.map((announcement, index) => (
            <AnnounceCard
              key={index}
              memberName={announcement.member?.memberName ?? ''}
              title={announcement.title}
              description={announcement.description}
              cubes={announcement.cubes}
              profileImageUrl={announcement.member?.profileImageUrl ?? ''}
              isEnd={announcement.isEnd}
              timelineId={announcement.timelineId}
              cardClassName={cardClassName}
            />
          ))}
        </div>
      </div>

      {/* ボタン表示の切り替え */}
      <div className="hidden md:block">
        {showButtons && selectedSnap + 1 > 1 && (
          <ScrollButton direction="left" onClick={scrollPrev}/>
        )}
        {showButtons && selectedSnap + 1 < snapCount && (
          <ScrollButton direction="right" onClick={scrollNext} />
        )}
      </div>
    </div>
  );
};

export default AccounceCardList;
