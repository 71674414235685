const ScrollButton = ({
    direction,
    onClick,
  }: {
    direction: 'left' | 'right';
    onClick: () => void;
  }) => {
    const isLeft = direction === 'left';
    return (
      <button
        className={`z-20 absolute top-20 ${
          isLeft ? '-left-7' : '-right-7'
        } border border-green bg-white p-4 rounded-full`}
        onClick={onClick}
      >
        <svg
          fill="#007559"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={
              isLeft
                ? 'M15.1248 21.0999L6.69982 12.6999C6.59982 12.5999 6.52882 12.4916 6.48682 12.3749C6.44482 12.2582 6.42415 12.1332 6.42482 11.9999C6.42482 11.8666 6.44549 11.7416 6.48682 11.6249C6.52815 11.5082 6.59915 11.3999 6.69982 11.2999L15.1248 2.8749C15.3582 2.64157 15.6498 2.5249 15.9998 2.5249C16.3498 2.5249 16.6498 2.6499 16.8998 2.8999C17.1498 3.1499 17.2748 3.44157 17.2748 3.7749C17.2748 4.10824 17.1498 4.3999 16.8998 4.6499L9.54982 11.9999L16.8998 19.3499C17.1332 19.5832 17.2498 19.8709 17.2498 20.2129C17.2498 20.5549 17.1248 20.8506 16.8748 21.0999C16.6248 21.3499 16.3332 21.4749 15.9998 21.4749C15.6665 21.4749 15.3748 21.3499 15.1248 21.0999Z'
                : 'M7.1499 21.1C6.8999 20.85 6.7749 20.554 6.7749 20.212C6.7749 19.87 6.8999 19.5743 7.1499 19.325L14.4749 12L7.1249 4.65C6.89157 4.41667 6.7749 4.125 6.7749 3.775C6.7749 3.425 6.8999 3.125 7.1499 2.875C7.3999 2.625 7.6959 2.5 8.0379 2.5C8.3799 2.5 8.67557 2.625 8.9249 2.875L17.3249 11.3C17.4249 11.4 17.4959 11.5083 17.5379 11.625C17.5799 11.7417 17.6006 11.8667 17.5999 12C17.5999 12.1333 17.5789 12.2583 17.5369 12.375C17.4949 12.4917 17.4242 12.6 17.3249 12.7L8.8999 21.125C8.66657 21.3583 8.3789 21.475 8.0369 21.475C7.6949 21.475 7.39924 21.35 7.1499 21.1Z'
            }
          />
        </svg>
      </button>
    );
  };
  
  export default ScrollButton;
  