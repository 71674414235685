import { FC, useState } from 'react'
import { Button } from '../../components/button'
import TabSwitcher from '../../components/tab/tabSwitcher'
import { QuestionForm } from '../../components/question/questionForm'
import { SingleWordForm } from "../../components/SingleWordInput/singleWordForm"
import { AnnounceForm } from '../../components/announce/AnnounceForm'
import { SendCubeForm } from '../../components/cube/sendCubeForm'
import Loading from 'react-loading'

interface Props {
  closeModal: () => void;
  activeTabName: string;
}

export const PostModal: FC<Props> = ({ closeModal, activeTabName }) => {
  const [isLoading, setIsLoading] = useState(false)

  // タブでモーダルコンテンツを切り替え
  const [activeTab, setActiveTab] = useState(activeTabName ?? "sendCube")
  const tabs = [
    { key: 'sendCube', label: 'キューブを送る' },
    { key: 'singleWord', label: 'ひとことを投稿する' },
    { key: 'announce', label: '告知する' },
    { key: 'question', label: '質問する' },
  ]

  return (
    <div
      className="fixed overflow-y-scroll md:p-6 p-4 rounded-xl bg-white hidden-scrollbar max-h-full top-[5%] right-0 bottom-0 left-0 flex flex-col md:top-1/2 md:bottom-[5%] md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:w-[590px] md:h-fit"
      role="dialog"
      aria-modal="true"
      aria-labelledby="send-cube__title"
    >
      <div className='flex flex-col max-sm:gap-6'>
        <div className="grid gap-1 w-full md:grid-rows-3">
          <Button className="col-start-3 justify-self-end" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              aria-label="Close modal"
            >
              <rect width="24" height="24" rx="12" fill="#EDF0F7" />
              <path
                d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                fill="#8A8F9F"
              />
            </svg>
          </Button>
          <div className="col-span-3 justify-center">
            <TabSwitcher
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs}
            />
          </div>
        </div>
        {isLoading ? (
          <Loading className="loading" type="spin" color="#007559" />
        ) : (
          <>
            {activeTab === 'sendCube' && (
              <SendCubeForm 
              closeModal={closeModal}
              setIsLoading={setIsLoading} />
            )}
            {activeTab === 'singleWord' && (
              <SingleWordForm 
              closeModal={closeModal}
              setIsLoading={setIsLoading} />
            )}
            {activeTab === 'announce' && (
              <AnnounceForm 
              closeModal={closeModal} 
              setIsLoading={setIsLoading} />
            )}
            {activeTab === 'question' && (
              <QuestionForm 
              closeModal={closeModal} 
              setIsLoading={setIsLoading} />
            )}
          </>
        )}
      </div>
    </div>
  )
}
